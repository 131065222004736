<template>
  <div class="flex overflow-hidden">
    <div class="py-8 pr-7 w-full" :class="sideModal === true ? 'mr-72' : ''">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Staff's List</h1>
        <button
          @click="addNewStaff()"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add Staff
        </button>
      </div>
      <div class="mb-10 flex gap-5" v-if="userRole === 'Executive Admin'">
        <label class="font-bold">Filter By Branch</label>
        <select
          v-model="branch_id"
          class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-2/12"
          placeholder="Branch"
          id="Branch"
        >
          <option
            v-for="branch in branches"
            :key="branch.branch_id"
            :value="branch.id.toString()"
          >
            {{ branch.branch_name }}
          </option>
        </select>
      </div>
      <div class="" v-if="staffs.length !== 0">
        <staff-table
          :allStaff="staffs"
          :perPage="perPage"
          :total="total"
          :currentPage="currentPage"
          @fetchPage="fetchAllStaff"
          @edit="editStaff"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <edit-staff
      :staff="editStaffDetail"
      v-if="sideModal"
      @close="sideModal = !sideModal"
    />
    <add-staff
      v-if="overlay"
      @close="overlay = !overlay"
      @created="fetchAllStaff(), (overlay = !overlay)"
    />
  </div>
</template>

<script>
import { fetchStaffByBranchAndPage } from "@/services/staff";

export default {
  name: "StaffIndex",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    staffTable: () => import("@/components/Staff/StaffTable.vue"),
    EditStaff: () => import("@/components/Staff/EditStaffModal.vue"),
    AddStaff: () => import("@/components/Staff/AddNewStaffModal.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  computed: {
    branches() {
      return this.$store.state.branches;
    },
    userBranch() {
      return this.$store.state.userDetail.branch_id;
    },
    userRole() {
      return this.$store.state.userDetail.staffroles[0].user_type_name;
    },
  },
  data() {
    return {
      sideModal: false,
      overlay: false,
      sideModalData: {},
      editStaffDetail: {},
      staffs: [],
      total: 0,
      perPage: 0,
      currentPage: 0,
      branch_id: "",
    };
  },
  methods: {
    addNewStaff() {
      this.overlay = true;
    },
    editStaff(staff) {
      this.sideModal = true;
      this.editStaffDetail = staff;
    },
    async fetchAllStaff(pageNumber = 1) {
      try {
        const response = await fetchStaffByBranchAndPage(
          this.branch_id || this.userBranch,
          pageNumber
        );
        this.staffs = response.data.staffs.data;
        if (this.staffs.length === 0) {
          this.$toast.info(
            "This branch does not have any staff yet, please add"
          );
        }
        this.perPage = response.data.staffs.per_page;
        this.currentPage = response.data.staffs.current_page;
        this.total = response.data.staffs.total;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
  },
  watch: {
    branch_id() {
      this.fetchAllStaff();
    },
  },
  mounted() {
    this.fetchAllStaff();
  },
};
</script>
